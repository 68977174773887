// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-gerris-1100-asv-tsx": () => import("./../../../src/pages/gerris-1100-asv.tsx" /* webpackChunkName: "component---src-pages-gerris-1100-asv-tsx" */),
  "component---src-pages-gerris-asv-surveyor-tsx": () => import("./../../../src/pages/gerris-asv-surveyor.tsx" /* webpackChunkName: "component---src-pages-gerris-asv-surveyor-tsx" */),
  "component---src-pages-gerris-asv-tsx": () => import("./../../../src/pages/gerris-asv.tsx" /* webpackChunkName: "component---src-pages-gerris-asv-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */)
}

